<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>商城管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/stock' }">图书库存</el-breadcrumb-item>
                <el-breadcrumb-item>编辑图书</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form v-loading="formLoading" ref="form" :model="form" :rules="rules" label-width="100px" size="mini">

                <el-form-item label="名称" label-position="left" prop="name" class="input">
                    <el-input style="width: 200px" v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="封面：" label-position="left" prop="pic">
                    <edit-cover :pic="imageUrl + form.pic" :uploadPath="uploadPath" @success="uploadSuccess">
                    </edit-cover>
                </el-form-item>

                <el-form-item label="库存" label-position="left" prop="stockNums">
                    <el-input style="width: 200px" v-model.number="form.stockNums"></el-input>
                </el-form-item>

                <el-form-item label="图书状态" label-position="left" class="input" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio-button label="上架"></el-radio-button>
                        <el-radio-button label="下架"></el-radio-button>
                    </el-radio-group>
                </el-form-item>




                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="onSubmit('form')">编辑</el-button>
                    <router-link to="/stock" class="router-link">
                        <el-button style="margin-left: 10px" size="small">取消</el-button>
                    </router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import editCover from '../../../components/editCover'
import { mapActions } from "vuex";
export default {
    name: "Add",
    components: { editCover },
    data() {
        return {
            //图片前缀
            imageUrl: config.imageUrl,
            //表单数据
            form: {
                status: '上架'
            },
            //验证
            rules: {
                name: [
                    { required: true, message: '请输入课程名称', trigger: 'blur' },
                ],
                pic: [
                    { type: 'string', required: true, message: '请选择图片封面', trigger: 'change' }
                ],
                stockNums: [
                    { required: true, message: '请输入库存数量', trigger: 'blur' },
                    { type: 'number', message: '库存必须为数字值' }
                ],
                status: [
                    { required: true, message: '请选择图书状态', trigger: 'change' },
                ],
            },
            //图书状态
            types: [
                {
                    id: 1,
                    name: '上架'
                },
                {
                    id: 2,
                    name: '下架'
                }
            ],
            //加载动画
            loading: false,
            uploadPath: 'course',
        }
    },
    methods: {
        ...mapActions('store', ['getBookStockGet','addBookStock']),
        //上传图片
        uploadSuccess(path) {
            this.form.pic = path
        },
        //保存表单
        onSubmit(formName) {
            this.form.status = this.form.status == '上架' ? 1 : 2
            this.form.id = this.$route.params.id
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addBookStock(this.form).then(res => {
                       if (res.res_info == 'ok') {
                            this.$router.go(-1);
                            this.$message.success('编辑成功')
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        //初始化数据
        initData() {
            this.formLoading = true
            this.getBookStockGet(this.$route.params.id).then(res => {
                res.data.status = res.data.status == 1 ? '上架' : '下架'
                this.uploadPath = res.data.pic
                res.data.stockNums = res.data.stock_nums
                this.form = res.data
                this.formLoading = false
            })
        },
    },
    created() {
        this.initData()
    }
}
</script>
